import React, { useState, useEffect, Fragment } from "react";
import {
  Flex,
  Button,
  Input,
  Text,
  Form,
  Loader,
  Label,
  Dialog as FluentDialog,
    Popup,
    FormButton,
    FormInput,
    FormDropdown
} from "@fluentui/react-northstar";
import {
  AcceptIcon,
  TrashCanIcon,
  EditIcon,
  SearchIcon,
} from "@fluentui/react-icons-northstar";
import { Dialog } from "@progress/kendo-react-dialogs";
import ConfigurationApiService from "../../../services/ConfigurationApiService";
import AuthHelper from "../../../services/auth-helper";
import {
  Grid,
  GridColumn as Column,
  GridNoRecords,
} from "@progress/kendo-react-grid";
import { orderBy } from "@progress/kendo-data-query";
import BusinessHourTemplate from "./workinghourstemplate";
import "../Dashboard/dashboard.scss";
import CenteredHeader from "../../Shared/Header/Header";
import useMediaQuery from "../../Shared/CustomHooks/useMediaQuery";
import WindowContext from "../../Shared/Context/Context";
import TeamsTimePicker from "../../Shared/uicomponents/TeamsTimePicker";
import NativeTimePicker from "../../Shared/uicomponents/NativeTimePicker";
import TeamsDatePicker from "../../Shared/uicomponents/TeamsDatePicker";
import NativeDatePicker from "../../Shared/uicomponents/NativeDatePicker";
import { format } from 'date-fns'
import { start } from "@progress/kendo-react-dateinputs";

const ScheduleTypes = [
  { id: 1, header: 'Daily', key: 0 },
  { id: 2, header: 'Weekly', key: 1 },
  { id: 3, header: 'Monthly', key: 2 },
  { id: 4, header: 'Yearly', key: 3 }
]

const EditSchedule = (props) => {
  const [loading, setLoading] = useState(false);
  const [scheduleId, setScheduleId] = useState(0);
  const [scheduleName, setScheduleName] = useState("");
  const [subject, setSubject] = useState("");
  const [emailTo, setEmailTo] = useState("");
  const [emailCc, setEmailCc] = useState("");
  const [startDate, setStartDate] = useState(new Date());
  const [time, setTime] = useState("");
  const [scheduleType, setScheduleType] = useState(ScheduleTypes[1]);
  const [selectedCards, setSelectedCards] = useState([]);
  const [selecteddashboards, setSelectedDashboards] = useState([]);
  const [cards, setCards] = useState([]);
  const [dashboards, setDashboards] = useState([]);
  const [dashboardCardsData, setDashboardCardsData] = useState([]);
  const [submitButtonState, setSubmitButtonState] = useState(0);
  const [validEmail, setValidEmail] = useState(props.validEmailTo);
  const [validEmailCC, setValidEmailCC] = useState(props.validEmailCc);

  const [formFilled, setFormFilled] = useState(false);
  const [isDemo, setIsDemo] = useState(false);
  const [isDemoMode, setIsDemoMode] = useState(global.localStorage.getItem("demoGlobal"));

  useEffect(() => {
    if (isDemoMode === "true") {
      setIsDemo(true);
    } else {
      setIsDemo(false);
    }
  }, []);
  useEffect(() => {
    setLoading(true);
    loadDashboardData();
    setSubmitButtonState(0);
  }, [props.selectedSchedule]);

  const loadDashboardData = () => {
    AuthHelper.getAccessToken(function (token) {
      ConfigurationApiService.GetDashboardList(token).then((response) => {
        if (response) {
          setStates(response);
        } else {
          setLoading(false);
        }
      });
    });
  }

  const setStates = (dashboardDetails) => {
    setDashboardCardsData(dashboardDetails);
    setScheduleId(props.selectedSchedule.scheduleID);
    setScheduleName(props.selectedSchedule.name);
    setSubject(props.selectedSchedule.subject);
    setEmailTo(props.selectedSchedule.emailTo.join('; '));
    setEmailCc(props.selectedSchedule.emailCC.join('; '));
    setStartDate(props.selectedSchedule.startDate);
    setTime(props.selectedSchedule.time);

    var indexSchedule = ScheduleTypes.findIndex(e => e.id === props.selectedSchedule.scheduleTypeID);
    setScheduleType(ScheduleTypes[indexSchedule]);

    var dashData = [];
    var selectedDashData = [];

    dashboardDetails.forEach((e) => {
        if (e.cards.length > 0) {
            var td = { id: e.id, header: e.dashboardName, key: e.id };
            dashData.push(td);
            if (props.selectedSchedule.dashboardID.includes(e.id)) {
                selectedDashData.push(td);
            }
        }
    });
    setDashboards(dashData);
    setSelectedDashboards(selectedDashData);

    if (props.selectedSchedule.dashboardID.length === 1) {
      var index = dashboardDetails.findIndex(e => e.id === props.selectedSchedule.dashboardID[0]);
      if (index > -1) {
        var cardData = [];
        var selectedCardData = [];
        dashboardDetails[index].cards.forEach(e => {
          var tc = { id: e.id, header: e.cardName, key: e.id }
          cardData.push(tc);
          if (props.selectedSchedule.cardIDs.includes(e.id)) {
            selectedCardData.push(tc)
          }
        })
        setCards(cardData);
        setSelectedCards(selectedCardData);
      }
    }
    setLoading(false);
  }

  const checkValid = () => {
    if (scheduleName.trim() !== "" && subject.trim() !== "" && emailTo.trim() !== ""
      && startDate !== null && time.trim() !== "" && scheduleType !== null && scheduleType.id !== null
      && selecteddashboards !== null && selecteddashboards.length !== 0
      && (selecteddashboards.length >= 1 || (selectedCards !== null && selectedCards.length !== 0))
    ) {
      return true;
    } else {
      return false;
    }
  }

  const handleUpdateSchedule = () => {
    setSubmitButtonState(1);
    setFormFilled(true);
    if (!checkValid() || !validEmail || !validEmailCC) {
      setTimeout(function () {
        setSubmitButtonState(0);
      }, 1000);
      setSubmitButtonState(3);
      return;
    }
    setLoading(true);
    var data = {
      scheduleID: scheduleId,
      name: scheduleName,
      emailTo: emailTo.split(";").map(ele => ele.trim()).filter(ele => ele != ""),
      emailCC: emailCc.split(";").map(ele => ele.trim()).filter(ele => ele != ""),
      subject: subject,
      startDate: startDate,
      time: time,
      scheduleTypeID: scheduleType.id,
      dashboardID: selecteddashboards.map(ele => ele.id),
      cardIDs: selecteddashboards.length > 1 ? [] : selectedCards.map(ele => ele.id)
    }

    AuthHelper.getAccessToken(function (token) {
      ConfigurationApiService.UpdateSchedule(data, token).then((response) => {
        if (response) {
          setSubmitButtonState(2);
          var indexSchedule = ScheduleTypes.findIndex(e => e.id === response.scheduleTypeID);
          response.scheduleType = ScheduleTypes[indexSchedule].header;
          props.handleUpdateConfirm(response);
          props.loadScheduleData();
        }
        else {
          setSubmitButtonState(3);
        }
        setLoading(false);
      })
        .finally(() => {
          setTimeout(function () {
            setSubmitButtonState(0);
          }, 1000);
        });
    });
  }

  const handleDashboardChange = (value) => {
    setSelectedDashboards(value);
    if (value.length === 1) {
      var index = dashboardCardsData.findIndex(e => e.id === value[0].id);
      if (index > -1) {
        var cardData = [];
        dashboardCardsData[index].cards.forEach(e => {
          var tc = { id: e.id, header: e.cardName, key: e.id }
          cardData.push(tc);
        })
        setCards(cardData);
        setSelectedCards(cardData);
      }
    } else {
      setCards([]);
      setSelectedCards([]);
    }
  }
  function emailValidation(value) {
    var flag = true;
    const regex = /^(([^<>()[\]\.,;:\s@\"]+(\.[^<>()[\]\.,;:\s@\"]+)*)|(\".+\"))@(([^<>()[\]\.,;:\s@\"]+\.)+[^<>()[\]\.,;:\s@\"]{2,})$/i;
    value.split(",").map(ele => {
      if (!ele.trim() || regex.test(ele.trim()) === false) {
        flag = false;
      }
    }
    )
    //if (!value || regex.test(value) === false) {
    //    return false;
    //}
    return flag;
  }
  function emailCcValidation(value) {
    var flag = true;
    const regex = /^(([^<>()[\]\.,;:\s@\"]+(\.[^<>()[\]\.,;:\s@\"]+)*)|(\".+\"))@(([^<>()[\]\.,;:\s@\"]+\.)+[^<>()[\]\.,;:\s@\"]{2,})$/i;
    value.split(",").map(ele => {
      if (regex.test(ele.trim()) === false) {
        flag = false;
      }
    }
    )
    //if (!value || regex.test(value) === false) {
    //    return false;
    //}
    if (value.trim().length === 0)
      flag = true;
    return flag;
  }
  function onChangeEmail(value) {
    if (emailValidation(value))
      setValidEmail(true);
    else
      setValidEmail(false);
    setEmailTo(value);
  }
  function onChangeEmailCC(value) {
    if (emailCcValidation(value))
      setValidEmailCC(true);
    else
      setValidEmailCC(false);
    setEmailCc(value);
  }
  return (
    <WindowContext.Consumer>
      {(context) => (
        loading ? <Loader /> :
          <Fragment>
            <Form
              className="formCardSettings"
              style={{ overflow: "hidden", minHeight: "50vh" }}
              onSubmit={handleUpdateSchedule}
            >
              <FormInput
                label="Schedule Name"
                name="scheduleName"
                required
                inline
                fluid
                defaultValue={scheduleName}
                value={scheduleName}
                maxLength="50"
                className="mb-3"
                onChange={(e, { value }) => { setScheduleName(value); }}
                onKeyPress={e => { e.which === 13 && e.preventDefault() }}
              />
              <FormInput
                label="Email Subject"
                name="emailSubject"
                required
                inline
                fluid
                defaultValue={subject}
                value={subject}
                maxLength="100"
                className="mb-3"
                onChange={(e, { value }) => { setSubject(value); }}
                onKeyPress={e => { e.which === 13 && e.preventDefault() }}
              />
              <div className="dropdownSection mb-3">
                <FormInput
                  label="Email To"
                  name="emailTo"
                  required
                  inline
                  fluid
                  defaultValue={emailTo}
                  value={emailTo}
                  errorMessage={!validEmail ? 'Please enter a valid value' : undefined}
                  onChange={(e, { value }) => { onChangeEmail(value); }}
                  onKeyPress={e => { e.which === 13 && e.preventDefault() }}
                />
                <FormInput
                  label="Email cc"
                  name="emailCc"
                  inline
                  fluid
                  defaultValue={emailCc}
                  value={emailCc}
                  errorMessage={!validEmailCC ? 'Please enter a valid value' : undefined}
                  onChange={(e, { value }) => { onChangeEmailCC(value); }}
                  onKeyPress={e => { e.which === 13 && e.preventDefault() }}
                />
              </div>
              <div className="dropdownSection mb-3">
                <div>
                  <label className="mb-0">Start Date</label>
                  {context.teams.hostClientType.web ||
                    context.teams.hostClientType.desktop ? (
                    <TeamsDatePicker
                      required={true}
                      value={startDate}
                      onChange={(value) => { setStartDate(value); }}
                      onKeyPress={e => { e.which === 13 && e.preventDefault() }}
                    />
                  ) : (
                    <NativeDatePicker
                      id="startDate"
                      required={true}
                      value={startDate}
                      onChange={(value) => { setStartDate(value); }}
                      onKeyPress={e => { e.which === 13 && e.preventDefault() }}
                    />
                  )}
                </div>
                <div>
                  <label>Start Time</label>
                  {context.teams.hostClientType.web ||
                    context.teams.hostClientType.desktop ? (
                    <TeamsTimePicker
                      required={true}
                      value={time}
                      onChange={(value) => { setTime(value); }}
                      onKeyPress={e => { e.which === 13 && e.preventDefault() }}
                    />
                  ) : (
                    <NativeTimePicker
                      id="startTime"
                      value={time}
                      onChange={(value) => { setTime(value); }}
                      onKeyPress={e => { e.which === 13 && e.preventDefault() }}
                      required={true}
                    />
                  )
                  }
                </div>
              </div>
              <div className="dropdownSection mb-3">
                <FormDropdown
                  label="Run Interval"
                  fluid
                  
                  placeholder="Select run interval"
                  items={ScheduleTypes}
                  value={scheduleType}
                  itemToValue={(obj) => { return obj.key; }}
                  onChange={(e, { value }) => { setScheduleType(value); }}
                  onKeyPress={e => { e.which === 13 && e.preventDefault() }}
                  noResultsMessage="We couldn't find any matches."
                />
                <FormDropdown
                  fluid
                  
                  label="Dashboards*"
                  search
                  multiple
                  placeholder="Select dashboards"
                  items={dashboards}
                  value={selecteddashboards}
                  itemToValue={(obj) => { return obj.key; }}
                  onChange={(e, { value }) => { handleDashboardChange(value); }}
                  onKeyPress={e => { e.which === 13 && e.preventDefault() }}
                  errorMessage={selecteddashboards.length === 0 && formFilled ? 'Please select a value' : undefined}
                  noResultsMessage="We couldn't find any matches."
                />
              </div>
              {selecteddashboards.length === 1 &&
                <FormDropdown
                  className="mb-5"
                  fluid
                  
                  label="Cards"
                  search
                  multiple
                  placeholder="Select cards"
                  items={cards}
                  value={selectedCards}
                  itemToValue={(obj) => { return obj.key; }}
                  //errorMessage={selectedCards.length === 0 && formFilled ? 'Please select a value' : undefined}
                  onChange={(e, { value }) => { setSelectedCards(value); }}
                  onKeyPress={e => { e.which === 13 && e.preventDefault() }}
                  noResultsMessage="We couldn't find any matches."
                />
              }
              <Flex
                gap="gap.small"
                className="align-items-center"
                fill
                hAlign="end"
                styles={{ height: '50px', paddingBottom: "0 !important", marginTop: "40 !important" }}
              >
                {isDemo && <div className="col text-right px-0">Exploring app with sample data.</div>}
                <FormButton content="Cancel" onClick={() => props.cancelHandler()} />
                <FormButton primary type="submit"
                  icon={submitButtonState === 2 && <AcceptIcon />}
                  disabled={isDemo}
                  loading={submitButtonState === 1}
                  content={
                    submitButtonState === 0
                      ? "Save"
                      : submitButtonState === 1
                        ? "Saving"
                        : submitButtonState === 2
                          ? "Saved"
                          : "Failed"
                  } />
              </Flex>
            </Form>
          </Fragment>
      )}
    </WindowContext.Consumer>
  );
}


const CellWithAction = (props) => {
  const data = props.dataItem;
  const [isDemo, setIsDemo] = useState(false);
  const [isDemoMode, setIsDemoMode] = useState(global.localStorage.getItem("demoGlobal"));

  useEffect(() => {
    if (isDemoMode === "true") {
      setIsDemo(true);
    } else {
      setIsDemo(false);
    }
  }, []);
  return (
    <WindowContext.Consumer>
      {
        context => (
          <td>
            <Flex hAlign="center">
              <Button
                onClick={() => props.onOpen(data)}
                icon={<EditIcon />}
                iconOnly
                title="Edit"
                size="small"
                primary
              />
              <FluentDialog
                cancelButton="Cancel"
                confirmButton="Delete"
                onConfirm={() => props.handleDeleteConfirm(data.scheduleID)}
                closeOnOutsideClick={false}
                defaultOpen={false}
                styles={{ maxWidth: "500px" }}
                trigger={
                  <Button
                    icon={<TrashCanIcon />}
                    styles={{ marginLeft: "8px" }}
                    disabled={isDemo}
                    iconOnly
                    title="Delete"
                    size="small"
                    primary
                  />
                }
                content={"Are you sure you want to delete this Schedule?"}
              />
            </Flex>
          </td>
        )
      }
    </WindowContext.Consumer>
  );
};

const DetailComponent = (props) => {
  const schedule = props.dataItem;
  return (
    <WindowContext.Consumer>
      {
        context => (
          <section>
            <table>
              {context.mediaCategory.sm &&
                <>
                  <tr>
                    <td style={{ width: "100px", fontWeight: "bold" }}>Start Date :</td>
                    <td >
                      <Text content={schedule?.startDate.toLocaleString('en-US', {
                        year: "numeric",
                        month: "long",
                        day: "numeric",
                      })} />
                    </td>
                  </tr>
                  <tr>
                    <td style={{ width: "100px", fontWeight: "bold" }}>Run Interval :</td>
                    <td >
                      <Text content={schedule?.scheduleType} />
                    </td>
                  </tr>
                  <tr>
                    <td style={{ width: "100px", fontWeight: "bold" }}>Time :</td>
                    <td >
                      <Text content={schedule?.time} />
                    </td>
                  </tr>
                </>
              }
              <tr>
                <td style={{ width: "100px", fontWeight: "bold" }}>Email To :</td>
                <td >
                  {
                    <Popup content={schedule.emailTo[0].split(",").map(ele => (
                      <Label circular content={ele} styles={{ marginRight: '5px' }} />
                    ))} trigger={<Label circular content={schedule.emailTo[0]} styles={{ marginRight: '5px' }} />} />

                  }
                </td>
              </tr>
              <tr>
                <td style={{ width: "100px", fontWeight: "bold" }}>Email CC :</td>
                <td >
                  {
                    <Popup content={schedule.emailCC.length > 0 ? schedule.emailCC[0].split(",").map(ele => (
                      <Label circular content={ele} styles={{ marginRight: '5px' }} />
                    )) : <Label circular content='N/A' styles={{ marginRight: '5px' }} />
                    } trigger={<Label circular content={schedule.emailCC[0]} styles={{ marginRight: '5px' }} />} />

                  }
                </td>
              </tr>
              <tr>
                <td style={{ width: "100px", fontWeight: "bold" }}>Subject :</td>
                <td >
                  <Text content={schedule.subject} />
                </td>
              </tr>
            </table>
          </section>
        )
      }
    </WindowContext.Consumer>

  );
};

const DashboardSchedulesConfig = (props) => {
  const [schedulesData, setSchedulesData] = useState(null);
  const [searchInput, setSearchInput] = useState("");
  const [open, setOpen] = useState(false);
  const [selectedSchedule, setSelectedSchedule] = useState(null);
  const [sort, setSort] = useState([]);
  const [validEmailTo, setValidEmailTo] = useState(false);
  const [validEmailCc, setValidEmailCc] = useState(true);
  useEffect(() => {
    setSearchInput("");
    loadScheduleData();
  }, []);
  function emailValidation(value) {
    var flag = true;
    const regex = /^(([^<>()[\]\.,;:\s@\"]+(\.[^<>()[\]\.,;:\s@\"]+)*)|(\".+\"))@(([^<>()[\]\.,;:\s@\"]+\.)+[^<>()[\]\.,;:\s@\"]{2,})$/i;
    value.split(",").map(ele => {
      if (!ele.trim() || regex.test(ele.trim()) === false) {
        flag = false;
      }
    }
    )
    //if (!value || regex.test(value) === false) {
    //    return false;
    //}
    return flag;
  }
  function emailCcValidation(value) {
    var flag = true;
    const regex = /^(([^<>()[\]\.,;:\s@\"]+(\.[^<>()[\]\.,;:\s@\"]+)*)|(\".+\"))@(([^<>()[\]\.,;:\s@\"]+\.)+[^<>()[\]\.,;:\s@\"]{2,})$/i;
    value.split(",").map(ele => {
      if (regex.test(ele.trim()) === false || ele.trim().length === 0) {
        flag = false;
      }
    }
    )
    //if (!value || regex.test(value) === false) {
    //    return false;
    //}
    if (value.trim().length === 0)
      flag = true;
    return flag;
  }
  const onOpen = (scheduleDetails) => {
    setValidEmailTo(emailValidation(scheduleDetails.emailTo[0]) ? true : false);
    setValidEmailCc(scheduleDetails.emailCc == undefined ? true : (emailCcValidation(scheduleDetails.emailCc[0]) ? true : false));
    setSelectedSchedule(scheduleDetails);
    setOpen(true);
  };

  const onCancel = () => {
    setOpen(false);
    setSelectedSchedule(null);
  };

  const loadScheduleData = () => {
    setSchedulesData(null);
    AuthHelper.getAccessToken(function (token) {
      ConfigurationApiService.GetSchedules(token).then((response) => {
        if (response != "") {
          const temp = response.map((item) => {
            item.startDate = new Date(item.startDate);//format(, 'yyyy-MMM-dd');

          });
          //console.log(response);
          setSchedulesData(response);
        } else {
          setSchedulesData([]);
        }
      });
    });
  };

  const expandChange = (event) => {
    let newData = schedulesData.map((item) => {
      if (item.scheduleID === event.dataItem.scheduleID) {
        item.expanded = !event.dataItem.expanded;
        item.startDate = new Date(item.startDate);
      }
      return item;
    });
    setSchedulesData(newData);
  };

  const handleUpdateConfirm = (scheduleDetails) => {
    var tempData = schedulesData;
    var indexId = tempData.findIndex(
      (x) => x.scheduleID === scheduleDetails.scheduleID
    );
    scheduleDetails.startDate = new Date(scheduleDetails.startDate);
    tempData[indexId] = scheduleDetails;
    setSchedulesData(tempData);
    onCancel();
  };

  const handleDeleteConfirm = (scheduleID) => {
    var tempData = schedulesData;
    setSchedulesData(null);
    AuthHelper.getAccessToken(function (token) {
      ConfigurationApiService.DeleteSchedule(scheduleID, token).then(
        (response) => {
          if (response !== undefined && response !== "") {
            var indexId = tempData.findIndex(
              (x) => x.scheduleID === scheduleID
            );
            tempData.splice(indexId, 1);
          }
          setSchedulesData(tempData);
        }
      );
    });
  };

  const CustomCellWithAction = (props) => {
    return (
      <CellWithAction
        {...props}
        onOpen={onOpen}
        handleDeleteConfirm={handleDeleteConfirm}
      />
    );
  };
  const sortChange = (event) => {
    if (event.sort.length === 0) {
      setSort(event.sort);
      return;
    }
    var sortOrder = event.sort[0].dir;
    var columnName = event.sort[0].field;
    if (columnName === "startDate") {
      let sortArray = [{ field: 'startDate', dir: sortOrder }];
      setSort(sortArray);
    }

  };

  return (
    <WindowContext.Consumer>
      {
        context => (
          <>
            <Flex gap="gap.small" padding="padding.medium" column fill hAlign="end">
              <Flex.Item>
                <Input
                  fluid
                  icon={<SearchIcon />}
                  clearable
                  value={searchInput}
                  placeholder="Search..."
                  onChange={(e, { name, value }) => {
                    setSearchInput(value);
                  }}
                />
              </Flex.Item>
              <Flex.Item>
                <Grid
                  style={{ maxHeight: "calc(100vh - 145px)" }}
                  detail={DetailComponent}
                  expandField="expanded"
                  onExpandChange={expandChange}
                  data={
                    schedulesData != null
                      ? orderBy(schedulesData.filter((obj) =>
                        obj.name.toLowerCase().includes(searchInput.toLowerCase())
                      ), sort)
                      : null
                  }
                  sortable={true}
                  sort={sort}
                  onSortChange={sortChange}
                >
                  <GridNoRecords>
                    {schedulesData != null ? "No records available" : <Loader />}
                  </GridNoRecords>
                  <Column
                    width={context.mediaCategory.sm ? "calc(100% - 100px)" : "calc(100% - 560px)"}
                    field="name"
                    title="Schedule Name"
                  />
                  {!context.mediaCategory.sm && (
                    <Column
                      width={"170px"}
                      className="text-center"
                      field="startDate"
                      title="Start Date"
                      format="{0:dd/MM/yyyy}"
                      headerCell={CenteredHeader}
                    />
                  )}
                  {!context.mediaCategory.sm && (
                    <Column
                      width={"150px"}
                      className="text-center"
                      field="scheduleType"
                      title="Run Interval"
                      headerCell={CenteredHeader}
                    />
                  )}
                  {!context.mediaCategory.sm && (
                    <Column
                      width={"150px"}
                      className="text-center"
                      field="nextRunInterval"
                      title="Next Run Interval"
                      headerCell={CenteredHeader}
                    />
                  )}
                  {!context.mediaCategory.sm && (
                    <Column
                      width={"100px"}
                      className="text-center"
                      field="time"
                      title="Time"
                      headerCell={CenteredHeader}
                    />
                  )}
                  <Column
                    width={"100px"}
                    title="Actions"
                    cell={CustomCellWithAction}
                    headerCell={CenteredHeader}
                  />
                </Grid>
              </Flex.Item>
            </Flex>
            {open && (
              <Dialog
                autoFocus={true}
                className="settingsDialog"
                title="Edit Schedule"
                onClose={() => onCancel()}
                width={650}
              >
                <EditSchedule
                  selectedSchedule={selectedSchedule}
                  validEmailTo={validEmailTo}
                  validEmailCc={validEmailCc}
                  handleUpdateConfirm={handleUpdateConfirm}
                  loadScheduleData={loadScheduleData}
                  cancelHandler={() => onCancel()}
                />
              </Dialog>
            )}
          </>
        )
      }
    </WindowContext.Consumer>
  );
};

export default DashboardSchedulesConfig;

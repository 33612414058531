import { Grid, GridColumn as Column } from "@progress/kendo-react-grid";
import "./StandardGrid.scss";
import { CircleIcon } from "@fluentui/react-icons-northstar";
import * as React from "react";
import { orderBy } from "@progress/kendo-data-query";
import { ExcelExport } from '@progress/kendo-react-excel-export';

// const data = [
//     {
//         MessageType: 'Group',
//         Sent: 647,
//         Replied: 453,
//         SentimentScore: 'Positive',
//         MentionedCount: 10,
//     },
//     {
//         MessageType: 'PeerToPeer',
//         Sent: 500,
//         Replied: 350,
//         SentimentScore: 'Neutral',
//         MentionedCount: 10,
//     },
//     {
//         MessageType: 'Team',
//         Sent: 500,
//         Replied: 350,
//         SentimentScore: 'Negative',
//         MentionedCount: 10,
//     }
// ];

class cellWithIcon extends React.Component {
    render() {
        let sentimentScore = this.props.dataItem.sentimentScore ? this.props.dataItem.sentimentScore : this.props.dataItem.sentiment;
        let className = "st_good";
        switch (sentimentScore) {
            case "Negative":
                className = "st_poor";
                break;
            case "Neutral":
                className = "st_average";
                break;
            case "Positive":
                className = "st_good";
                break;
            default:
                className = "st_good";
                sentimentScore = "Positive";
        }
        return (
            <td>
                <CircleIcon className={className} /> {sentimentScore}
            </td>
        );
    }
}


class cellWithCallIcon extends React.Component {
    render() {
        let avgCallQuality = this.props.dataItem.averagecallQuality;
        let _className = "st_good";
        switch (avgCallQuality) {
            case "Poor":
                _className = "st_poor";
                break;
            case "Average":
                _className = "st_average";
                break;
            case "Good":
                _className = "st_good";
                break;
            default:
                _className = "st_none";
                avgCallQuality = "N/A";
        }
        return (
            <td className={this.props.className + "text-left"}>
                <CircleIcon className={_className} /> {avgCallQuality}
            </td>
        );
    }
}

export default function StandardGrid({ data, widgetId, downloadCard, valueAfterCardDownload }) {
    if (widgetId === 47 || widgetId === 48) {
        for (var i = 0; i < data.length; i++)
            data[i].dateTime = new Date(data[i].dateTime);
    }

    const [sort, setSort] = React.useState([]);
    const [cardDownload, setCardDownload] = React.useState(false);
    const _export = React.useRef(null);
    React.useEffect(() => {
        setCardDownload(downloadCard)
        if (cardDownload) {
            if (_export.current !== null) {
                _export.current.save();
            }
            setCardDownload(false);
            valueAfterCardDownload(false);
        }
    });

    return (
        <>
            {data && data.length > 0 && widgetId === 6 && (
                <ExcelExport data={data} ref={_export}>
                    <Grid
                        className="standardGrid"
                        style={{ height: "100%" }}
                        data={orderBy(data, sort)}
                        sortable={true}
                        sort={sort}
                        onSortChange={(e) => {
                            setSort(e.sort);
                        }}
                    >
                        <Column field="messageType" title="Message Type" width={100} locked />
                        <Column field="sent" title="Sent" width={50} />
                        <Column field="replied" title="Replied" width={65} />
                        <Column
                            field="sentimentScore"
                            title="Sentiment Score"
                            cell={cellWithIcon}
                            width={115}
                        />
                    </Grid>
                </ExcelExport>
            )}
            {data && data.length > 0 && widgetId === 27 && (
                <ExcelExport data={data} ref={_export}>
                    <Grid
                        className="standardGrid"
                        style={{ height: "100%" }}
                        data={orderBy(data, sort)}
                        sortable={true}
                        sort={sort}
                        onSortChange={(e) => {
                            setSort(e.sort);
                        }}
                    >
                        <Column field="groupName" title="Team Name" width={110} locked />
                        <Column field="replied" title="Replies" width={85} />
                        <Column field="posted" title="Posts" width={85} />
                        <Column field="mentions" title="Mentions" width={80} />
                        <Column field="reactions" title="Reactions" width={82} />
                        <Column
                            field="sentimentScore"
                            title="Sentiment Score"
                            cell={cellWithIcon}
                            width={115}
                        />
                        <Column field="meetings" title="Calls" width={85} />
                    </Grid>
                </ExcelExport>
            )}
            {data && data.length > 0 && widgetId === 28 && (
                <ExcelExport data={data} ref={_export}>
                    <Grid
                        className="standardGrid"
                        style={{ height: "100%" }}
                        data={orderBy(data, sort)}
                        sortable={true}
                        sort={sort}
                        onSortChange={(e) => {
                            setSort(e.sort);
                        }}
                    >
                        <Column field="groupName" title="Team Name" locked width={150} />
                        <Column
                            width={150}
                            field="atLeastDaysNotice"
                            title="Invitation sent with at least a day's notice"
                        />
                        <Column width={150} field="attendance" title="High attendance" />
                        <Column
                            width={150}
                            field="noMessage"
                            title="No chat during meeting"
                        />
                        <Column
                            width={150}
                            field="joinedBeforeMeetingStarts"
                            title="Online meeting started on time"
                        />
                        <Column
                            width={150}
                            field="duringWorkingHours"
                            title="During working hours"
                        />
                        <Column
                            width={150}
                            field="noOverlap"
                            title="No overlap with other meetings"
                        />
                    </Grid>
                </ExcelExport>
            )}
            {data && data.length > 0 && widgetId === 29 && (
                <ExcelExport data={data} ref={_export}>
                    <Grid
                        className="standardGrid"
                        style={{ height: "100%" }}
                        data={orderBy(data, sort)}
                        sortable={true}
                        sort={sort}
                        onSortChange={(e) => {
                            setSort(e.sort);
                        }}
                    >
                        <Column field="userName" title="Name" width={80} locked />
                        <Column field="totalOrganized" title="Organised" width={78} />
                        <Column field="totalParticipated" title="Participated" width={90} />
                        <Column field="totalTalkTime" title="Total Talk Time" width={103} />
                    </Grid>
                </ExcelExport>

            )}
            {data && data.length > 0 && widgetId === 35 && (
                <ExcelExport data={data} ref={_export}>
                    <Grid
                        className="standardGrid"
                        style={{ height: "100%" }}
                        data={orderBy(data, sort)}
                        sortable={true}
                        sort={sort}
                        onSortChange={(e) => {
                            setSort(e.sort);
                        }}
                    >
                        <Column field="teamName" title="Team Name" locked width={100} />
                        <Column field="calls" title="Calls" width={80} />
                        <Column field="totalTalkTime" title="Total Talk Time" width={116} />
                        <Column field="posted" title="Posts" width={116} />
                        <Column field="replied" title="Replies	" width={126} />
                        <Column
                            field="sentimentScore"
                            title="Sentiment Score"
                            cell={cellWithIcon}
                            width={126}
                        />
                    </Grid>
                </ExcelExport>

            )}
            {data && data.length > 0 && widgetId === 36 && (
                <ExcelExport data={data} ref={_export}>
                    <Grid
                        className="standardGrid"
                        style={{ height: "100%" }}
                        data={orderBy(data, sort)}
                        sortable={true}
                        sort={sort}
                        onSortChange={(e) => {
                            setSort(e.sort);
                        }}
                    >
                        <Column width={150} field="teamName" title="Team Name" locked />
                        <Column width={100} field="memberANDguestCount" title="Members" />
                        <Column width={100} field="ownerCount" title="Owners" />
                        <Column width={150} field="dateCreated" title="Creation Date" />
                        <Column width={150} field="totalChat" title="Posts" />
                        <Column width={150} field="totalReplied" title="Replies" />
                        <Column width={100} field="reactionCount" title="Reactions" />
                        <Column width={100} field="mentionedCount" title="Mentions" />
                        <Column width={150} field="totalCalls" title="Calls" />
                        <Column width={150} field="mostRecentTime" title="Last Activity" />
                    </Grid>
                </ExcelExport>

            )}
            {data && data.length > 0 && widgetId === 44 && (
                <ExcelExport data={data} ref={_export}>
                    <Grid
                        className="standardGrid"
                        style={{ height: "100%" }}
                        data={orderBy(data, sort)}
                        sortable={true}
                        sort={sort}
                        onSortChange={(e) => {
                            setSort(e.sort);
                        }}
                    >
                        <Column width={100} field="userName" title="User Name" locked />
                        <Column width={180} field="callingNumber" title="Calling Number" />
                        <Column width={100} field="calldirection" title="Call DIrection" />
                        <Column width={100} field="dateTime" title="Date Time" />
                        <Column width={100} field="status" title="Status" />
                        <Column width={100} field="unreturned" title="Unreturned" />
                        <Column width={100} field="totalTalkTime" title="Total Talk Time" />
                        <Column width={100} field="averagecallQuality" title="Call Quality" cell={cellWithCallIcon} />
                    </Grid>
                </ExcelExport>
            )}
            {data && data.length > 0 && widgetId === 45 && (
                <ExcelExport data={data} ref={_export}>
                    <Grid
                        className="standardGrid"
                        style={{ height: "100%" }}
                        data={orderBy(data, sort)}
                        sortable={true}
                        sort={sort}
                        onSortChange={(e) => {
                            setSort(e.sort);
                        }}
                    >
                        <Column width={80} field="callQueue" title="Call Queue" locked />
                        <Column width={80} field="totalCalls" title="Calls Offered" />
                        <Column width={80} field="answered" title="Answered" />
                        <Column width={80} field="answeredInTarget" title="Answered In Target" />
                        <Column width={80} field="missed" title="Missed" />
                        <Column width={80} field="uniqueMissed" title="Unique Missed" />
                        <Column width={80} field="returned" title="Returned" />
                        <Column width={80} field="returnedInTime" title="Returned in Target" />
                        <Column width={80} field="unreturned" title="Unreturned" />
                        <Column width={80} field="averageAnswerTime" title="Avg. Answer Time" />
                        <Column width={80} field="longestWaitTime" title="Longest Wait Time" />
                        <Column width={80} field="totalTalkTime" title="Total Talk Time" />
                        <Column width={80} field="averagecallQuality" title="Avg. Call Quality" cell={cellWithCallIcon} />
                    </Grid>
                </ExcelExport>
            )}
            {data && data.length > 0 && widgetId === 47 && (
                <ExcelExport data={data} ref={_export}>
                    <Grid
                        className="standardGrid"
                        style={{ height: "100%" }}
                        data={orderBy(data, sort)}
                        sortable={true}
                        sort={sort}
                        onSortChange={(e) => {
                            setSort(e.sort);
                        }}
                    >
                        <Column width={100} field="callQueue" title="Call Queue" locked />
                        <Column width={100} field="callingNumber" title="Calling Number" />
                        <Column width={100} field="dateTime" title="Date Time" filter="date" format="{0:dd/MM/yyyy hh:mm:ss a}" />
                        <Column width={100} field="agent" title="Agent" />
                        <Column width={100} field="status" title="Status" />
                        <Column width={100} field="duration" title="Duration" />
                        <Column width={100} field="answerTime" title="Answer Time" />
                        <Column width={100} field="totalTalkTime" title="Total Talk Time" />
                        <Column width={100} field="averagecallQuality" title="Average Call Quality" cell={cellWithCallIcon} />
                    </Grid>
                </ExcelExport>
            )}
            {data && data.length > 0 && widgetId === 48 && (
                <ExcelExport data={data} ref={_export}>
                    <Grid
                        className="standardGrid"
                        style={{ height: "100%" }}
                        data={orderBy(data, sort)}
                        sortable={true}
                        sort={sort}
                        onSortChange={(e) => {
                            setSort(e.sort);
                        }}
                    >
                        <Column width={100} field="callingNumber" title="Calling Number" locked />
                        <Column width={100} field="agent" title="Agent" />
                        <Column width={100} field="callQueue" title="Call Queue" />
                        <Column width={100} field="dateTime" title="Date Time" filter="date" format="{0:dd/MM/yyyy hh:mm:ss a}" />
                        <Column width={100} field="status" title="Status" />
                        <Column width={100} field="duration" title="Duration" />
                        <Column width={100} field="answerTime" title="Answer Time" />
                        <Column width={100} field="totalTalkTime" title="Total Talk Time" />
                        <Column width={100} field="averagecallQuality" title="Average Call Quality" cell={cellWithCallIcon} />
                    </Grid>
                </ExcelExport>
            )}
            {data && data.length > 0 && widgetId === 49 && (
                <ExcelExport data={data} ref={_export}>
                    <Grid
                        className="standardGrid"
                        style={{ height: "100%" }}
                        data={orderBy(data, sort)}
                        sortable={true}
                        sort={sort}
                        onSortChange={(e) => {
                            setSort(e.sort);
                        }}
                    >
                        <Column width={1} field="callingNumber" title="Calling Number" locked />
                        <Column width={1} field="dateTime" title="Date Time" />
                        {data[0].organisatFilterType === "callQueue" ? <Column width={1} field="callQueue" title="Call Queue" /> : <Column width={1} field="agent" title="Agent" />}
                    </Grid>
                </ExcelExport>
            )}
            {data && data.length > 0 && widgetId === 50 && (
                <ExcelExport data={data} ref={_export}>
                    <Grid
                        className="standardGrid"
                        style={{ height: "100%" }}
                        data={orderBy(data, sort)}
                        sortable={true}
                        sort={sort}
                        onSortChange={(e) => {
                            setSort(e.sort);
                        }}
                    >
                        <Column width={120} field="callingNumber" title="Calling Number" locked />
                        <Column width={100} field="dateTimeOfLastCall" title="Date/Time of first missed call" />
                        <Column width={100} field="countMissedCall" title="Number of Missed Calls" />
                        {data[0].organisatinfilterType === "CallQueue" ? <Column width={100} field="callQueueName" title="Call Queue" /> : <Column width={100} field="agent" title="Agent" />}
                        <Column width={100} field="outsideTargetTime" title="Outside Target Time" />
                    </Grid>
                </ExcelExport>
            )}
            {data && data.length > 0 && widgetId === 51 && (
                <ExcelExport data={data} ref={_export}>
                    <Grid
                        className="standardGrid"
                        style={{ height: "100%" }}
                        data={orderBy(data, sort)}
                        sortable={true}
                        sort={sort}
                        onSortChange={(e) => {
                            setSort(e.sort);
                        }}
                    >
                        <Column width={100} field="agent" title="Agent" locked />
                        <Column width={80} field="offered" title="Calls Offered" />
                        <Column width={80} field="answered" title="Answered" />
                        <Column width={80} field="bounced" title="Bounced" />
                        <Column width={80} field="talkTime" title="Talk Time" />
                        <Column width={100} field="avgTalkTime" title="Avg. Talk Time" />
                        <Column width={100} field="avgAnswerTime" title="Avg. Answer Time" />
                    </Grid>
                </ExcelExport>
            )}
            {data && data.length > 0 && widgetId === 54 && (
                <ExcelExport data={data} ref={_export}>
                    <Grid
                        className="standardGrid"
                        style={{ height: "100%" }}
                        data={orderBy(data, sort)}
                        sortable={true}
                        sort={sort}
                        onSortChange={(e) => {
                            if (e.sort.length === 0) {
                                setSort(e.sort);
                                return;
                            }
                            var sortOrder = e.sort[0].dir;
                            var columnName = e.sort[0].field;
                            if (columnName === "percentAnsweredStr") {
                                let sortArray = [{ field: 'percentAnswered', dir: sortOrder },
                                { field: 'percentAnsweredStr', dir: sortOrder }];
                                setSort(sortArray);
                            } else { setSort(e.sort); }
                        }}
                    >
                        <Column width={100} field="callqueue" title="Call Queue" locked />
                        <Column width={100} field="offered" title="Calls Offered" />
                        <Column width={120} field="percentAnsweredStr" title="% Answered (Total)" />
                        <Column width={80} field="bounced" title="Bounced" />
                        <Column width={100} field="talkTime" title="Total Talk Time" />
                        <Column width={120} field="avgTalkTime" title="Avg. Talk Time" />
                        <Column width={120} field="avgAnswerTime" title="Avg. Answer Time" />
                    </Grid>
                </ExcelExport>
            )}
        </>
    );
}

import { useState, useEffect } from "react";
import "./dashboard.scss";
import AuthenticatedRoute from "../../RouteHelper";

import { Route, Switch } from "react-router-dom";

import DashGrid from "./dashboard";
import AddCard from "./addcard";
import DashHome from "./dashboards";

const Dashboards = () => {  
  return (
    <Switch>
      <AuthenticatedRoute exact path="/dashboards" component={DashHome} />
      <AuthenticatedRoute
        path="/dashboards/:operation/:dashboardId"
        component={DashGrid}
      />
    </Switch>
  );
};

export default Dashboards;

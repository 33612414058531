import React from 'react';
import { useState, useEffect, useContext } from "react";
// import { OpenOutsideIcon } from '@fluentui/react-northstar'
import './ProvisioningState.scss';
import DashboardApiService from "../../services/DashboardApiService";
import AppOverview from "../Shared/uicomponents/AppOverview"
import AuthHelper from "../../services/auth-helper";
import { useHistory, useLocation } from "react-router-dom";
import CreateForDemoAPIService from "../../services/CreateForDemoAPIService";
import popoutIcon from "../../assets/images/a365_provision_open.png";
import helpChat from "../../assets/images/a365_provisionong_chat.png";
import helpUpdates from "../../assets/images/a365_provisionong_updates.png";
import helpProductTour from "../../assets/images/a365_provisionong_product_tour.png";
import signupProvision from "../../assets/images/a365_provision_signup.png";
import WindowContext from "../Shared/Context/Context";
import { SignUpConsumer } from '../Shared/Context/SignUpContext';
import { Text, Checkbox, Loader } from "@fluentui/react-northstar";

const ProvisioningState = () => {
    const [isDemo, setIsDemo] = useState(false);
    const [impersonateMode, setImpersonateMode] = useState(false);
    const [globalimpMode, setGlobalimpMode] = useState(window.localStorage.getItem("impersonationMode"));
    const history = useHistory();
    const { state } = useLocation();
    const { from } = state || { from: { pathname: "/" } };
    //const isAnalytics = state.isAnalytics;
    //const isAdmin = state.isAdmin;
    const tenantStatus1 = state.tenantStatus;
    const subscriptionStatus = state.subscriptionStatus;
    const customerStatus = state.customerStatus;
    const isDeleted = state.isDeleted;
    const isFirstLogin = state.isFirstLogin;
    const isReporting = state.isReporting;
    const isUpdatesSeen = state.isUpdatesSeen;
    const globalDemoModeOverride = state.globalDemoModeOverride;
    const [loading, setLoading] = useState(false);
    const [loaderDemoMode, setLoaderDemoMode] = useState(false);
    const demoDashboardJourneyState = state.demoDashboardJourneyState;
    const dashboardJourneyState = state.dashboardJourneyState;

    const _context = useContext(WindowContext);

    const openInNewTab = (url) => {
        const newWindow = window.open(url, '_blank', 'noopener,noreferrer')
        if (newWindow) newWindow.opener = null
    }

    const setDemo = (isChecked) => {
        setLoaderDemoMode(true);
        let isDashboardsTab = from.pathname.includes('dashboards');
        if ((tenantStatus1 === 4 || tenantStatus1 === 5) && (subscriptionStatus === 0 || subscriptionStatus === 6)) {
            global.localStorage.setItem("demoGlobal", "true");
            if (demoDashboardJourneyState < 2) {
                GetMyAnalyticDashboard(1);
            }
            else {                
                if (isDashboardsTab) {
                    history.push("/dashboards");
                }
                else {
                    history.replace(from.pathname);
                }
            }
        }
        else {
            AuthHelper.getAccessToken(function (token) {
                CreateForDemoAPIService.CreateUserForDemo(token).then((response) => {
                    if (response !== undefined && response !== "") {
                        global.localStorage.setItem("demoGlobal", "true");
                        global.localStorage.setItem("isCustomerNotRegistered", "true");
                        setIsDemo(true);
                        AddDashboard(isDashboardsTab);
                    }
                });
            });
        }
    }
    const GetMyAnalyticDashboard = (isDemo) => {
        var myAnalyticsDashboardId = 0;
        var myAnalyticsDashboardLink = "/dashboards/view/";
        var dashboardLink = "/dashboards"
        var reloadApp = "";
        AuthHelper.getAccessToken(function (token) {
            DashboardApiService.GetDashboards(token, 1).then((response) => {
                if (response == null || response.journeyState < 0) {
                    if (from.pathname.includes('dashboards')) {
                        history.push(dashboardLink);
                    }
                    else {
                        history.replace(from.pathname);
                    }
                }
                else if (response != null) {
                    _context.journeyState.setlatestDemoDashboardJourneyState(response.journeyState);

                    response?.dashboards?.forEach((e) => {
                        if (e.isDemo === true && e.name === "My Analytics" && e.isDefault)
                            myAnalyticsDashboardId = e.userDashboardsID
                    });

                    if (myAnalyticsDashboardId !== 0) {
                        myAnalyticsDashboardLink += myAnalyticsDashboardId;


                        reloadApp = response.journeyState === 0 && myAnalyticsDashboardId !== 0 ? myAnalyticsDashboardLink : dashboardLink;

                        if (from.pathname.includes('dashboards')) {
                            history.push(reloadApp);
                        }
                        else {
                            history.replace(from.pathname);
                        }
                    }
                    else {
                        AddDashboard(from.pathname.includes('dashboards'));
                    }
                }
            });
        });
    }

    const AddDashboard = (isDashboardsTab) => {
        var myAnalyticsDashboardId = 0;
        var myAnalyticsDashboardLink = "/dashboards/view/";
        var dashboardLink = "/dashboards"
        var reloadApp = "";
        AuthHelper.getAccessToken(function (token1) {
            DashboardApiService.AddDefaultDashboards(true, token1).then((response1) => {
                debugger
                if (response1 == null || response1.journeyState < 0 || response1.routeDashboardId === 0) {
                    if (isDashboardsTab) {
                        history.push(dashboardLink);
                    }
                    else {
                        history.replace(from.pathname);
                    }
                }
                else if (response1 != null) {
                    _context.journeyState.setlatestDemoDashboardJourneyState(response1.journeyState);

                    myAnalyticsDashboardId = response1.routeDashboardId;
                    if (myAnalyticsDashboardId !== 0) {
                        myAnalyticsDashboardLink += myAnalyticsDashboardId;
                    }

                    reloadApp = response1.journeyState === 0 && response1.routeDashboardId !== 0 ? myAnalyticsDashboardLink : dashboardLink;
                    
                    if (isDashboardsTab) {
                        history.push(reloadApp);
                    }
                    else {
                        history.replace(from.pathname);
                    }
                }
            });
        });
    }

    const handleglobalimpMode = () => {
        window.localStorage.removeItem("impersonationMode");
        window.localStorage.removeItem("impersonationToken");
        setGlobalimpMode(false);
        window.location.replace("/dashboards");
    }

    const onValuesUpdate = () => {
        
        setLoading(true);
        var myAnalyticsDashboardId = 0;
        var myAnalyticsDashboardLink = "/dashboards/view/";
        var dashboardLink = "/dashboards"
        var reloadApp = "";

        let isDashboardsTab = from.pathname.includes('dashboards');
        global.localStorage.setItem("demoGlobal", "false");
        AuthHelper.getAccessToken(function (token) {
            
            DashboardApiService.UpdateAppUpdates(true, token).then((response) => {
                if (response) {

                }
            });
        });


        AuthHelper.getAccessToken(function (token) {
            DashboardApiService.AddDefaultDashboards(false, token).then((response1) => {
                debugger
                if (response1 == null || response1.journeyState < 0 || response1.routeDashboardId === 0) {
                    if (isDashboardsTab) {
                        history.push(dashboardLink);
                    }
                    else {
                        history.replace(from.pathname);
                    }
                }
                else if (response1 != null) {
                    _context.journeyState.setlatestDashboardJourneyState(response1.journeyState);

                    myAnalyticsDashboardId = response1.routeDashboardId;
                    if (myAnalyticsDashboardId !== 0) {
                        myAnalyticsDashboardLink += myAnalyticsDashboardId;
                    }

                    reloadApp = response1.journeyState === 0 && response1.routeDashboardId !== 0 ? myAnalyticsDashboardLink : dashboardLink;

                    if (isDashboardsTab) {
                        history.push(reloadApp);
                    }
                    else {
                        history.replace(from.pathname);
                    }
                }
            });
        });
        
    };

    useEffect(() => {
        if (process.env.REACT_APP_HIDE_ZOHO_SUPPORT === 'false') {
            //add chat with experts area
            const chatWithExpertDiv = document.getElementById('chatWithExpert');
            if (chatWithExpertDiv != null && chatWithExpertDiv != undefined && chatWithExpertDiv?.childNodes.length == 0) {
                const anchorTag = document.createElement('a');
                var textNode = document.createTextNode("Chat with an expert");
                anchorTag.setAttribute("onclick", '$zoho.salesiq.floatwindow.visible("show")');
                anchorTag.setAttribute("type", 'button');
                anchorTag.appendChild(textNode);

                chatWithExpertDiv.appendChild(anchorTag);
            }
        }
        
        AuthHelper.getAccessToken(function (token) {
            DashboardApiService.GetSignUpUrl(token).then(
                (response) => {
                    if (response) {
                        global.localStorage.setItem("signUpUrl", response);

                    }
                }
            );
        });
    }, []);

    return (
        <WindowContext.Consumer>
            {(context) => (
                <SignUpConsumer>
                    {
                        (tenantStatus) => (
                            //((subscriptionStatus === 0 || subscriptionStatus === 6) && (isFirstLogin === true && isReporting === 1)) ?
                            //    <AppOverview state={{ from: state.from }} />
                            //:
                            <div className="d-grid">
                                <div className="container a365-div-center">
                                    {impersonateMode && (

                                        <div className="row">
                                            <div className="impersonateMode d-flex align-items-center justify-content-end pt-2 pb-1 pr-0 border-bottom">
                                                <Text className="text-nowrap mr-md-0 mr-2" content="Remote Assist:" size="medium" />
                                                <Checkbox
                                                    toggle
                                                    checked={globalimpMode}
                                                    onClick={(event, isChecked) => {
                                                        handleglobalimpMode(isChecked.checked);
                                                    }}
                                                />
                                            </div>
                                        </div>
                                    )}
                                    {loading && <Loader size='small' label="Getting things ready..." labelPosition='end' />}
                                    {loaderDemoMode && process.env.REACT_APP_HIDE_DEMO === 'false' ? <Loader size='small' label="Loading Demo Mode..." labelPosition='end' /> : <></>}
                                    
                                    <div className="row bg-4B549A p-4">
                                        <div className="col">
                                            <div className="row">
                                                <div className="col text-light mb-3 mt-2 px-0">
                                                    <h4 className='a365-fw-200'>{isUpdatesSeen === 0 && !isFirstLogin && (subscriptionStatus === 0 || subscriptionStatus === 6) ? `${process.env.REACT_APP_APP_BRAND_NAME} has been updated` :
                                                        `Welcome to ${process.env.REACT_APP_APP_BRAND_NAME}`}</h4>
                                               </div>
                                            </div>
                                            <div className="row">
                                                <div className="col-12 px-0 col-md-8 bg-white a365-br-8 overflow-clip">
                                                    <img src={isUpdatesSeen === 0 && !isFirstLogin && (subscriptionStatus === 0 || subscriptionStatus === 6) ? "/images/a365_provisionig_2_3x.png" : "/images/a365_provisionig_1_3x.png"} alt="" width='100%' />
                                                </div>
                                                <div className="col pt-3 pt-md-0 pr-0 text-white d-flex flex-column justify-content-between">
                                                    <div className="row">
                                                            <div className="col">
                                                                <span className='fs-1_5 lh-1_25 a365-fw-200'>
                                                                    {((tenantStatus1 === 4 || tenantStatus1 === 5) && subscriptionStatus === 8) ?
                                                                        <span className='fs-1_5 a365-fw-200'>
                                                                            <span>Try the app while your Microsoft administrator completes set up and your data synchronizes.</span>
                                                                        </span>
                                                                    :
                                                                    (/*tenantStatus1 === 5 &&*/ (subscriptionStatus === 0 || subscriptionStatus === 6) && dashboardJourneyState === 0 && isReporting === 1 && isFirstLogin === true) ? `Let's get started.` :
                                                                        (tenantStatus1 === 2 || tenantStatus1 === 3) ? 'Try the app while your Microsoft administrator completes the set up.' :
                                                                        (tenantStatus1 === null || tenantStatus1 === -1 || tenantStatus1 === 100) ? 'Sign up to view analytics for your organization, or try our demo now.' :
                                                                        (isFirstLogin === false && (isUpdatesSeen === 0 && subscriptionStatus !== 8)) ? `If you haven't already done so, please sign out of your Microsoft Teams account and re-enter ${process.env.REACT_APP_APP_BRAND_NAME} using the link below.` :
                                                                        (isReporting === 0 && subscriptionStatus !== 8) ? 'Try our demo or to view analytics for your organization, ask your administrator for reporting access.' : ''
                                                                    }
                                                                </span>
                                                        </div>
                                                    </div>
                                                    <div className="row text-center mt-3 mb-4 mx-auto">
                                                        {(tenantStatus1 === null || tenantStatus1 === -1 || tenantStatus1 === 100) &&
                                                            <div className="col-auto py-3">
                                                                <a type='button' onClick={() => openInNewTab(global.localStorage.getItem("signUpUrl"))}><img className='p-2' src={signupProvision} alt="signout" width={56} />
                                                                    <br />
                                                                    <span className='p-2'>Sign up</span>
                                                                </a>
                                                            </div>
                                                        }
                                                        <div className="col-auto py-3">
                                                            {
                                                                (tenantStatus1 === 5 && (subscriptionStatus === 0 || subscriptionStatus === 6) && (isFirstLogin === true || isUpdatesSeen === 0 || dashboardJourneyState === 0) && isReporting === 1) ?
                                                                    <a type="button" onClick={() => { onValuesUpdate() }}><img className='p-2' src={popoutIcon} alt="Open" width={56} />
                                                                        <br />
                                                                        {/*<span className='p-2'>Open {process.env.REACT_APP_APP_BRAND_NAME}</span>*/}
                                                                        <span className='p-2'>Open collaboration analytics</span>
                                                                     </a> :
                                                                    process.env.REACT_APP_HIDE_DEMO === 'true' ? <></> :
                                                                        <a type="button" onClick={(event, isChecked) => { setDemo(isChecked); }}>
                                                                            <img className='p-2' src={popoutIcon} alt="signout" width={56} />
                                                                            <br />
                                                                            <span className='p-2'>Access demo</span>
                                                                        </a>
                                                            }
                                                        </div>
                                                    </div>
                                                    <div className="row">
                                                        <div className="col">
                                                            <div className="row">
                                                                <div className="col-auto"><h5>Need some help?</h5></div>
                                                            </div>
                                                            <div className="row my-3 align-items-center">
                                                                <div className="col-auto pr-0">
                                                                    <div className="rounded-circle p-2 bg-B6B5D550">
                                                                        <img src={helpUpdates} alt="" width={20} />
                                                                    </div>
                                                                </div>
                                                                <div className="col"> {
                                                                    ((subscriptionStatus === 0 || subscriptionStatus === 6) && (isFirstLogin === true || dashboardJourneyState === 0)) ? <a className="text-white" type='button' onClick={() => window.open(`${process.env.REACT_APP_KNOWLEDGEBASE_WELCOME_LINK}`, "noopener")}>Visit our knowledge base</a> :
                                                                        (tenantStatus1 === 2 || ((tenantStatus1 === 4 || tenantStatus1 === 5) && subscriptionStatus === 8)) ? <a className="text-white" type='button' onClick={() => window.open(`${process.env.REACT_APP_KNOWLEDGEBASE_SETUPPROGRESS_LINK}`, "noopener")}>Visit our knowledge base</a> :
                                                                            (tenantStatus1 === null || tenantStatus1 === -1 || tenantStatus1 === 100) ? <a className="text-white" type='button' onClick={() => window.open(`${process.env.REACT_APP_KNOWLEDGEBASE_BEFORESIGNUP_LINK}`, "noopener")}>Visit our knowledge base</a> :
                                                                                isReporting === 0 ? <a className="text-white" type='button' onClick={() => window.open(`${process.env.REACT_APP_KNOWLEDGEBASE_DEMOMODE_LINK}`, "noopener")}>Visit our knowledge base</a> :
                                                                                    isUpdatesSeen === 0 ? <a className="text-white" type='button' onClick={() => window.open(`${process.env.REACT_APP_APP_UPDATES_LINK}`, "noopener")}>Read what's new in this update</a>
                                                                                        : <a className="text-white" type='button' onClick={() => window.open(`${process.env.REACT_APP_HELP_LINK}`, "noopener")}>Read what's new in this update</a>
                                                                }
                                                                </div>
                                                            </div>
                                                            {
                                                                (true /*(subscriptionStatus === 0 || subscriptionStatus === 6) && (isFirstLogin === true && isReporting === 1)*/) ?
                                                                    <div className="row my-3 align-items-center">
                                                                        <div className="col-auto pr-0">
                                                                            <div className="rounded-circle p-2 bg-B6B5D550">
                                                                                <img src={helpProductTour} alt="" width={20} />
                                                                            </div>
                                                                        </div>
                                                                        <div className="col"> 
                                                                            <a className="text-white" type='button' onClick={() => window.open(`${process.env.REACT_APP_PRODUCT_TOUR}`, "noopener")}>Watch our product tour</a> 
                                                                        </div>
                                                                    </div> : <></>
                                                            }
                                                            {
                                                                process.env.REACT_APP_HIDE_ZOHO_SUPPORT === 'true' ? <></> :
                                                                    <div className="row align-items-center">
                                                                        <div className="col-auto pr-0">
                                                                            <div className="rounded-circle p-2 bg-B6B5D550">
                                                                                <img src={helpChat} alt="" width={20} />
                                                                            </div>
                                                                        </div>
                                                                        <div id="chatWithExpert" className="col text-white">
                                                                        </div>
                                                                    </div>
                                                            }
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>

                                </div>
                            </div>
                        )
                    }
                </SignUpConsumer>
            )}

        </WindowContext.Consumer>
    );
}

export default ProvisioningState;
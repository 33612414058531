import { useEffect, useState } from "react";
import * as microsoftTeams from "@microsoft/teams-js";
import { ThemePrepared } from "@fluentui/react-northstar";
//import teamsTheme from '@fluentui/react-northstar/dist/es/themes/teams';
import { teamsTheme, teamsDarkTheme, teamsHighContrastTheme } from '@fluentui/react-northstar/dist/es';

import * as siteVariables from './TeamsV2Theme';
import { createTheme, mergeThemes } from '@fluentui/styles';

export const checkInTeams = () => {
    // eslint-disable-next-line dot-notation
    const microsoftTeamsLib = microsoftTeams || window["microsoftTeams"];

    if (!microsoftTeamsLib) {
        return false; // the Microsoft Teams library is for some reason not loaded
        
    }

    if ((window.parent === window.self && window.nativeInterface) ||
        window.name === "embedded-page-container" ||
        window.name === "extension-tab-frame") {
        return true;
    }
    return false;
};

export const getQueryVariable = (variable) => {
    const query = window.location.search.substring(1);
    const vars = query.split("&");
    for (const varPairs of vars) {
        const pair = varPairs.split("=");
        if (decodeURIComponent(pair[0]) === variable) {
            return decodeURIComponent(pair[1]);
        }
    }
    return undefined;
};

/**
 * Microsoft Teams React hook
 * @param options optional options
 * @returns A tuple with properties and methods
 * properties:
 *  - inTeams: boolean = true if inside Microsoft Teams
 *  - fullscreen: boolean = true if in full screen mode
 *  - theme: Fluent UI Theme
 *  - themeString: string - representation of the theme (default, dark or contrast)
 *  - context - the Microsoft Teams JS SDK context
 * methods:
 *  - setTheme - manually set the theme
 */

const HOSTCLIENTTYPE = {
    web: false,
    desktop: false,
    android: false,
    ios: false
}

export const useTeams = ({options}) => {
    const [inTeams, setInTeams] = useState(false);
    const [fullScreen, setFullScreen] = useState(undefined);
    const [theme, setTheme] = useState(teamsTheme);
    const [themeString, setThemeString] = useState("default");
    const [initialTheme] = useState((options && options.initialTheme) ? options.initialTheme : getQueryVariable("theme"));
    const [context, setContext] = useState();
    const [hostClientType, setHostClientType] = useState(undefined);
    const themeChangeHandler = (theme) => {
        setThemeString(theme || "default");
        switch (theme) {
            case "dark":
                setTheme(teamsDarkTheme);
                break;
            case "contrast":
                setTheme(teamsHighContrastTheme);
                break;
            case "teamsv2":
                setTheme(mergeThemes(
                    createTheme(
                        {
                            siteVariables: {
                              brand: 'darkred',
                              brand04: '#8F5873',
                              gray08: '#A8516E8C',
                              gray06: '#f4c2c2',
                              gray03: '#757575',
                            },
                            componentVariables: {
                              Button: {
                                height: '24px',
                                minWidth: '24px',
                                borderRadius: '8px',
                                color: 'darkred',
                                secondaryColor: '#ffffff',
                                secondaryBorderColor: 'transparent',
                                secondaryBackgroundColor: '#6699CC',
                                secondaryBackgroundColorHover: '#91A3B0',
                              },
                            },
                            componentStyles: {
                              Button: {
                                icon: {
                                  fontSize: '12px',
                                },
                              },
                            },
                          },
                      'teams-v2',
                    ),
                    teamsTheme
                  ));
            case "default":
            default:
                setTheme(teamsTheme);
        }
        document.body.className = (theme || "default") + (inTeams ? " inTeams" : "");
    };

    //const tabFrameContextChangeHandler = (_contentUrl) => {
    //    if (inTeams) {
    //        console.log('tabFrameContextChangeHandler: ' + _contentUrl);
    //        microsoftTeams.setFrameContext({ contentUrl: _contentUrl, websiteUrl: 'https://apptest.uk.analytics-365.com' });
    //    }
    //}

    useEffect(() => {
        if (checkInTeams()) {
            setInTeams(true);
            if (inTeams) {
                microsoftTeams.initialize(() => {
                    microsoftTeams.getContext(context => {
                        debugger
                        setContext(context);
                        setFullScreen(context.isFullScreen);
                        themeChangeHandler(context.theme);
                        if(context.hostClientType){                            
                            setHostClientType({...HOSTCLIENTTYPE, [context.hostClientType] : true});
                        }
                    });
                    microsoftTeams.registerFullScreenHandler((isFullScreen) => {
                        setFullScreen(isFullScreen);
                    });
                    microsoftTeams.registerOnThemeChangeHandler(themeChangeHandler);
                    //microsoftTeams.setFrameContext({ contentUrl: options.contentUrl, websiteUrl: 'https://apptest.uk.analytics-365.com' })
                });
            }
        }
        else{
            setHostClientType({...HOSTCLIENTTYPE, ["web"] : true});
        }
    }, [inTeams]);

    useEffect(() => {
        themeChangeHandler(initialTheme);        
    }, [initialTheme]);

    return [{ inTeams, fullScreen, theme, context, themeString, hostClientType }, { setTheme: themeChangeHandler }];
}

